/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { SelectItemRenderer } from 'react-dropdown-select';

type CustomOptionLabelProps = SelectItemRenderer<any> & {
  optionRender: (item: Pick<SelectItemRenderer<any>, 'item'>) => React.ReactNode;
};

/**
 * Mocks the original Option from react-dropdown-select
 * https://github.com/sanusart/react-dropdown-select/blob/f0ad1a04634257c0ea46522a53a75f121bc4cce7/src/components/Option.js
 */
export const CustomOptionLabel: React.FC<CustomOptionLabelProps> = ({
  item,
  props,
  methods,
  optionRender,
}) => {
  const { disabled, closeOnSelect } = props;

  return (
    <span role="listitem" className="react-dropdown-select-option custom-option-label">
      <span className="react-dropdown-select-option-label">{optionRender(item)}</span>
      <span
        className="react-dropdown-select-option-remove custom-option-label__remove"
        onClick={(event) => !disabled && methods.removeItem(event, item, !!closeOnSelect)}
      >
        &times;
      </span>
    </span>
  );
};
