import { FeatureFlagsClient, Team } from '@getvim/feature-flags';

export const featureFlagsClient = new FeatureFlagsClient({
  namespace: 'vim_connect',
});

export const featureFlagsClientOrderOptimization = new FeatureFlagsClient({
  namespace: 'vim_connect',
  team: Team.OrderOptimization,
});
