import React from 'react';
import classNames from 'classnames';
import { AlignEnum, ColorNameEnum, SizeEnum } from '../../types';
import Text from '../text';
import './index.less';

export enum BannerTypes {
  INFORMATION = 'information',
  DISCLAIMER = 'disclaimer',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface BannerProps {
  type: BannerTypes;
  text: string | JSX.Element;
  icon?: JSX.Element;
  textColor?: ColorNameEnum;
  className?: string;
}

const Banner = ({ type, text, icon, textColor, className }: BannerProps) => (
  <div className={classNames('atomic-banner', `atomic-banner-${type}`, className ?? '')}>
    {icon && <div className="atomic-banner-icon">{icon}</div>}
    <Text
      size={SizeEnum['12px']}
      align={AlignEnum.left}
      className="atomic-banner-text"
      colorName={textColor}
      text={text}
    />
  </div>
);

export default Banner;
