import { StandardError } from './base-error';
import { ErrorCodes } from './error-codes';

export class SyncExtensionConnectorTimeoutError extends StandardError {
  constructor(type: string, payload: any, timeout: number, id = '', ackResponse?) {
    super(`Didn't get response from extension/electron`, ErrorCodes.INTERNAL_ERROR, {
      type,
      payload,
      timeout,
      id,
      ackResponse,
    });
  }
}
