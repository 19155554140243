import React, { ReactNode, ReactElement } from 'react';
import { SizeEnum, WeightEnum } from '../../types';
import Text from '../text';
import './index.less';

interface ApplicationTitleProp {
  title: string;
  notifications?: number;
  logoUrl?: string;
  children?: ReactNode;
  toggleAppButton?: ReactElement<any, any>;
}

export default function ApplicationTitle({
  title,
  notifications,
  logoUrl,
  children,
  toggleAppButton,
}: ApplicationTitleProp) {
  return (
    <div className="application-title">
      <Text className="label" size={SizeEnum['14px']}>
        <Text weight={WeightEnum.semibold} inline>
          {title}
        </Text>
        &nbsp;
        {notifications !== undefined && `(${notifications})`}
      </Text>
      {children}

      {logoUrl ? <img src={logoUrl} className="insurer-logo" /> : null}
      {toggleAppButton}
    </div>
  );
}
