import { ErrorCodes } from './error-codes';

export class StandardError extends Error {
  constructor(
    public message: string,
    public code = ErrorCodes.INTERNAL_ERROR,
    properties: any = {},
  ) {
    super(message);
    if (properties) {
      Object.keys(properties).forEach((key) => {
        this[key] = properties[key];
      });
    }
  }

  public toJSON() {
    return Object.getOwnPropertyNames(this)
      .filter((key) => key !== 'properties')
      .reduce(
        (serialized, key) => ({
          ...serialized,
          [key]: this[key],
        }),
        {},
      );
  }
}
