export enum Insurer {
  CENTENE = 'centene',
  PREMERA = 'premera_blue_cross',
  UHC = 'united_health_care',
  FLORIDA_BLUE = 'blue_cross_blue_shield_fl',
  ANTHEM = 'anthem_blue_cross_ca',
  ELEVANCE = 'elevance',
  CIGNA = 'cigna',
  HUMANA = 'humana',
  MOCK_DEMO_INSURER = 'mock-demo-insurer',
}
