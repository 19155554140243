/**
 * Error codes that standard actions / connectors might return.
 * based on Http error codes
 */
export enum ErrorCodes {
  INTERNAL_ERROR = 500,
  INVALID_INPUT = 400,
  NOT_FOUND = 404,
  BAD_GATEWAY = 502,
  TIMEOUT = 504,
  SERVICE_UNAVAILABLE = 503,
  UNPROCESSABLE_ENTITY = 422,
  UNAUTHORIZED = 401,
}
