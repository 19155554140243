import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  MutableRefObject,
  forwardRef,
} from 'react';
import get from 'lodash.get';
import classNames from 'classnames';
import DropDownSelect, { SelectRenderer, SelectItemRenderer } from 'react-dropdown-select';
import debounce from 'debounce';
import { CustomOptionLabel } from './CustomOptionLabel';

import './index.less';

export type SelectItemRendererProps = SelectItemRenderer<any>;

const Select = forwardRef((props: any, ref) => <DropDownSelect {...props} ref={ref} />);
Select.displayName = 'Select';
export type SelectRef = MutableRefObject<{ select: React.RefObject<HTMLInputElement> }>;

export interface SelectInputProps {
  id: string;
  name: string;
  options: any[];
  values?: any[];
  onChange: (event: any) => void;
  className?: string;
  placeholder?: string;
  labelField?: string;
  label?: string;
  valueField?: string;
  optionLabelField?: string;
  keepSelectedInList?: boolean;
  sortOptions?: boolean;
  setTouched?: (state: boolean) => void;
  noDataLabel?: string;
  create?: boolean;
  clearable?: boolean;
  multi?: boolean;
  closeOnSelect?: boolean;
  loading?: boolean;
  searchFn?: ({ props, state, methods }: SelectRenderer<any>) => any[] | undefined | void;
  isDisabled?: boolean;
  dropdownHandle?: boolean;
  itemRenderer?: (props: SelectItemRendererProps) => JSX.Element;
  searchable?: boolean;
  hideValueOnInput?: boolean;
  showChevronIcon?: boolean;
  multiline?: boolean;
  withDebounce?: boolean;
  debounceTime?: number;
  noResultsLabel?: string;
  error?: string | undefined;
  noInputLabel?: string;
  selectRef?: SelectRef;
}

export default function SelectInput({
  id,
  name,
  options,
  values = [],
  onChange,
  className,
  placeholder = '',
  label,
  labelField = 'label',
  valueField,
  optionLabelField,
  keepSelectedInList = false,
  sortOptions = false,
  setTouched,
  noResultsLabel = 'No results found',
  create = false,
  loading = false,
  clearable = false,
  multi = false,
  closeOnSelect = true,
  searchFn,
  isDisabled,
  dropdownHandle = false,
  itemRenderer,
  searchable = true,
  hideValueOnInput = false,
  showChevronIcon = false,
  multiline = false,
  withDebounce = true,
  debounceTime = 500,
  error,
  noInputLabel,
  selectRef,
}: SelectInputProps) {
  const [active, setActive] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState(options);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [lastSearchedInput, setLastSearchedInput] = useState<string | null>(null);
  const [resultsInput, setResultsInput] = useState<string | null>(null);

  const searchMethod = debounce(
    (args: SelectRenderer<any>) => {
      if (searchFn) {
        setResultsLoading(true);
        setLastSearchedInput(args.state.search);
        return searchFn?.(args);
      }

      return undefined;
    },
    withDebounce ? debounceTime : 0,
  );

  const onSearch = useCallback(
    (args: SelectRenderer<any>) => searchMethod(args) || selectOptions,
    [selectOptions, searchMethod],
  );

  useEffect(() => {
    if (!resultsLoading) {
      setSelectOptions(options);
      setResultsInput(lastSearchedInput);
    }
  }, [resultsLoading, options, lastSearchedInput]);

  useEffect(() => {
    if (!loading) {
      setResultsLoading(false);
    }
  }, [loading]);

  const onSelectedChange = (value, onChangeProp) => {
    setHasValue(value.length);

    onChangeProp(value);
  };

  const sortedOptions = useMemo(
    () =>
      [...selectOptions].sort((a, b) => {
        if (a[labelField] < b[labelField]) {
          return -1;
        }
        if (a[labelField] > b[labelField]) {
          return 1;
        }

        return 0;
      }),
    [selectOptions, labelField],
  );

  let customOptions = {};

  if (optionLabelField) {
    customOptions = {
      ...customOptions,
      optionRenderer: (props) => (
        <CustomOptionLabel {...props} optionRender={(item) => get(item, optionLabelField)} />
      ),
    };
  }

  const shouldHideValue = useMemo(() => !multi && hideValueOnInput, [multi, hideValueOnInput]);

  const noDataTitle = useMemo(() => {
    let title;

    if (error) {
      title = error;
    } else if (!resultsInput && noInputLabel) {
      title = noInputLabel;
    } else if (!selectOptions.length && noResultsLabel) {
      title = noResultsLabel;
    }

    return title;
  }, [resultsInput, selectOptions, error, noInputLabel, noResultsLabel]);

  return (
    <div
      className={classNames(
        'react-dropdown-select-wrap',
        { 'hide-value': shouldHideValue },
        { multiline },
      )}
    >
      <Select
        ref={selectRef}
        searchable={searchable}
        multi={multi}
        name={name}
        loading={loading}
        options={sortOptions ? sortedOptions : selectOptions}
        values={values}
        onChange={(value) => onSelectedChange(value, onChange)}
        className={className}
        placeholder={placeholder}
        clearable={clearable}
        labelField={labelField}
        valueField={valueField}
        searchBy={labelField}
        keepSelectedInList={keepSelectedInList}
        onDropdownOpen={() => setActive(true)}
        onDropdownClose={() => {
          setActive(false);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          setTouched && setTouched(true);
        }}
        additionalProps={{ id }}
        noDataLabel={noDataTitle}
        closeOnSelect={closeOnSelect}
        create={create}
        searchFn={onSearch}
        {...customOptions}
        disabled={isDisabled}
        dropdownHandle={dropdownHandle}
        itemRenderer={itemRenderer}
        addPlaceholder={shouldHideValue ? values?.[0]?.label : undefined}
      />
      {label && (
        <label
          className={classNames('select-dropdown-label', {
            'input-active': active || hasValue || values.length > 0,
          })}
        >
          {label}
        </label>
      )}
      {showChevronIcon && (
        <div className={classNames('select-chevron-icon', { active })}>
          <i className="icon-chevron-down" />
        </div>
      )}
    </div>
  );
}
